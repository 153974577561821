/*

*/
/*//*/
* {
  box-sizing: border-box;
  font-family: "Noto Sans KR";
  word-break: break-all;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  outline: none;
  -webkit-tap-highlight-color: transparent !important;
}

/* 스크롤바 전체 */
*::-webkit-scrollbar {
  width: 4px;
}

/* 스크롤 막대 */
*::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 20px;
}

/* 스크롤 막대 영역 */
*::-webkit-scrollbar-track {
  background: none;
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
button,
input,
textarea,
p,
select,
blockquote,
th,
td,
img,
a {
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #000;
  font-size: 1em;
  word-wrap: break-word;
}

html,
body {
  font-size: 16px;
  width: 100%;
  height: 100% !important;
  overflow-x: hidden;
  overscroll-behavior: contain;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  display: block;
  border: 0;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
}

input,
select,
textarea {
  outline: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*반응형*/
@media (max-width: 1800px) {
}
@media (max-width: 1680px) {
}
@media (max-width: 1600px) {
  html,
  body {
    font-size: 15px;
  }
}
@media (max-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (max-width: 1366px) {
  html,
  body {
    font-size: 13px;
  }
}
@media (max-width: 1280px) {
  html,
  body {
    font-size: 12px;
  }
}
@media (max-width: 1152px) {
  html,
  body {
    font-size: 11px;
  }
}
@media (max-width: 1024px) {
  html,
  body {
    font-size: 13px;
  }
}
@media (max-width: 960px) {
}
@media (max-width: 768px) {
  /* 스크롤바 전체 */
  *::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 650px) {
}
@media (max-width: 550px) {
}
@media (max-width: 490px) {
}
@media (max-width: 400px) {
}
@media (max-width: 340px) {
}
/*//*/
